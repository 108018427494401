import styles from "./staffImportTableTitles.module.scss";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { StaffImportTableForFormik } from "stores/StaffModule/types/StaffImportTable";
import Checkbox from "shared/ui/Inputs/Checkbox";
import SelectMultiTable from "shared/ui/TableInputs/SelectMultiTable";
import { getValues } from "shared/utils/helpers/getValues";

const StaffImportTableTitles = () => {
  const { staffImportStore, menuStore } = useStores();

  const { setFieldValue, setFieldTouched, values } =
    useFormikContext<StaffImportTableForFormik>();

  const handleSetSelect = () => {
    values.staff.forEach((_item, ind) => {
      if (staffImportStore.staffStatus[ind] === "correct") {
        values.selectAll
          ? setFieldValue(`staff.${ind}.select`, 0)
          : setFieldValue(`staff.${ind}.select`, 1);
      }
    });
    setFieldTouched("selectAll");
  };

  const handleSetCompany = () => {
    values.companyAll &&
      values.companyAll.length &&
      values.staff.forEach((_item, ind) => {
        setFieldValue(`staff.${ind}.company`, values.companyAll);
        setTimeout(() => {
          setFieldTouched(`staff.${ind}.company`);
        }, 100);
      });
    setFieldTouched("companyAll");
  };

  return (
    <tr className={styles.tableTitle}>
      <td>
        <p>№</p>
      </td>
      {staffImportStore.titles.map((title) => {
        switch (title) {
          case "select":
            return (
              <td key={title}>
                <div className={styles.selectCell}>
                  <p>{staffImportStore.tableCols[title]?.title}</p>
                  <Checkbox
                    name="selectAll"
                    id="selectAll"
                    onChange={handleSetSelect}
                    disabled={
                      !getValues(staffImportStore.staffStatus).some(
                        (status) => status === "correct"
                      )
                    }
                  />
                </div>
              </td>
            );
          case "company":
            return (
              <td key={title} className={styles.cellWithInput}>
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes(title)
                  })}
                >
                  {staffImportStore.tableCols[title]?.title}
                </p>
                <SelectMultiTable
                  key={title}
                  name="companyAll"
                  options={staffImportStore.selects?.company}
                  placeholder="Выберите компании"
                  valueName="id"
                  withBorder
                  isMassEdit
                  onClickMass={handleSetCompany}
                  className={{ scrollboard: styles.selectMultiPopper }}
                />
              </td>
            );
          case "phone_1":
          case "phone_2":
            return (
              <td key={title}>
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes("phone")
                  })}
                >
                  {`${staffImportStore.tableCols["phone"]?.title} ${parseInt(
                    title.split("_")[1],
                    10
                  )}`}
                </p>
              </td>
            );

          default:
            return (
              <td
                key={title}
                className={classNames("", {
                  [styles.patronymic]: title === "patronymic",
                  [styles.patronymic__shadow]:
                    title === "patronymic" && menuStore.scroll.scrollLeft
                })}
              >
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes(title)
                  })}
                >
                  {staffImportStore.tableCols[title]?.title}
                </p>
              </td>
            );
        }
      })}
    </tr>
  );
};

export default observer(StaffImportTableTitles);
