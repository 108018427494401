import styles from "./buildingOneStaffWindowTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { Button, ButtonTheme } from "shared/ui/Button";
import StatusIcon from "shared/ui/StatusIcon";

const BuildingOneStaffWindowTabs = () => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  return (
    <div className={styles.tabs}>
      {buildingOneStaffStore.getEventTypeOptions(id).map((status) => {
        const handleSetStatus = () =>
          buildingOneStaffStore.setSelectedStatus(status.id);
        return (
          <Button
            key={status.id}
            onClick={handleSetStatus}
            theme={ButtonTheme.TAB}
            selected={buildingOneStaffStore.selectedStatus === status.id}
          >
            <div className={styles.button}>
              <StatusIcon
                icon={status.custom?.icon}
                color={
                  buildingOneStaffStore.selectedStatus !== status.id
                    ? status.custom.color_api
                    : "default"
                }
              />
              {status.title}
            </div>
          </Button>
        );
      })}
    </div>
  );
};
export default observer(BuildingOneStaffWindowTabs);
