import styles from "./staffAddNew.module.scss";
import LoadedComponent from "widgets/LoadedComponent";

import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

import { Input } from "shared/ui/Inputs/Input";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import Switch from "shared/ui/Inputs/Switch";
import PageTitle from "shared/ui/PageTitle";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import InformationCard from "shared/ui/Cards/InformationCard";
import Select from "shared/ui/Inputs/Select";
import AutoComplete from "shared/ui/Inputs/AutoComplete";
import RadioButton from "shared/ui/Inputs/RadioButton";
import Textarea from "shared/ui/Inputs/Textarea";
import Alert from "shared/ui/Alert";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";

import { ReactComponent as IconCircle } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { DoubleFieldsRequestData } from "stores/StaffModule/staffAddNew";
import { getValidationSchema } from "./validation";
import { without } from "lodash";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";
import Modal from "shared/ui/Modal";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

const StaffAddNew = () => {
  const { menuStore, staffDaDataStore, staffAddNewStore } = useStores();
  const { isLoadingAutoData } = staffDaDataStore;
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  const [requestData, setRequestData] = useState<{
    [key: string]: DoubleFieldsRequestData;
  }>({
    name: {
      val: "",
      touched: false,
      error: false
    },
    surname: {
      val: "",
      touched: false,
      error: false
    },
    birthday: {
      val: "",
      touched: false,
      error: false
    }
  });

  const navigate = useNavigate();
  const [nameCalendarOpened, setNameCalendarOpened] = useState("");
  const [isCompaniesOpened, setIsCompaniesOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConvictionField, setIsConvictionField] = useState(false);
  const [requiredFields, setRequiredFields] = useState(
    staffAddNewStore.requiredFields
  );

  const changeOpenedWindows = (name: string) => {
    nameCalendarOpened === name
      ? setNameCalendarOpened("")
      : setNameCalendarOpened(name);
  };

  useEffect(() => {
    if (
      requestData.surname.val &&
      requestData.name.val &&
      requestData.birthday.val &&
      getValues(requestData).some(({ touched }) => touched) &&
      getValues(requestData).every(({ error }) => !error)
    ) {
      staffAddNewStore.getDoubleFields({
        surname: requestData.surname.val,
        name: requestData.name.val,
        birthday: requestData.birthday.val
      });
      setIsCompaniesOpened(false);
    } else staffAddNewStore.resetMassEmployees();
  }, [requestData]);

  useEffect(() => {
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("add");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/add",
      title: "Сотрудники"
    });
    staffAddNewStore.getCompaniesList();
    staffAddNewStore.setErrorMessage({});
    if (!staffAddNewStore.inputTitles) {
      staffAddNewStore.setFormFields();
    }
    return () => {
      staffAddNewStore.resetMassEmployees();
    };
  }, []);

  useEffect(() => {
    isLoading !== staffAddNewStore.isLoadingForField &&
      setIsLoading(staffAddNewStore.isLoadingForField);
  }, [staffAddNewStore.isLoadingForField]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="position-relative">
        <PageTitle leftCol title="Добавление сотрудников" isStickyTitle />
        <LoadedComponent
          isLoading={staffAddNewStore.isLoading}
          error={staffAddNewStore.error}
        >
          <>
            {staffAddNewStore.inputTitles ? (
              <Formik
                initialValues={{
                  ...staffAddNewStore.newStaffObj,
                  conviction: []
                }}
                validationSchema={() =>
                  getValidationSchema(
                    getKeys(staffAddNewStore.newStaffObj),
                    requiredFields,
                    isConvictionField
                  )
                }
                onSubmit={async (values) => {
                  const id = await staffAddNewStore.addOneStaff(values);
                  if (values["add_more"] === 0) {
                    navigate(`../staff/id=${id}`);
                  } else {
                    setIsOpenSuccessModal(true);
                  }
                }}
                validateOnChange
                validateOnBlur
              >
                {({
                  values,
                  initialValues,
                  errors,
                  isValid,
                  dirty,

                  setFieldValue,
                  setFieldTouched,

                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setErrors,
                  setTouched
                }) => {
                  const check = (name: string) => {
                    if (
                      values[name] &&
                      values[name] !== requestData[name]["val"] &&
                      !errors[name]
                    )
                      return true;
                  };

                  if (check("surname") || check("name") || check("birthday")) {
                    setTimeout(
                      () =>
                        setRequestData({
                          surname: {
                            val: values["surname"] as string,
                            touched: true,
                            error: false
                          },
                          name: {
                            val: values["name"] as string,
                            touched: true,
                            error: false
                          },
                          birthday: {
                            val: values["birthday"] as string,
                            touched: true,
                            error: false
                          }
                        }),
                      0
                    );
                  }

                  const keys = getKeys(values) as string[];

                  return (
                    <Form className={styles.form}>
                      {staffAddNewStore.massEmployees &&
                      staffAddNewStore.massEmployees.length > 0 ? (
                        <div className={styles.containerCard}>
                          {staffAddNewStore.massEmployees.map((mass) => {
                            return (
                              <InformationCard
                                key={mass.uid}
                                isAlert
                                title="Найдены похожие сотрудники!"
                                icon="attention"
                              >
                                {getKeys(mass).map((key) => {
                                  return <div key={key}>{mass[key]}</div>;
                                })}
                              </InformationCard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                      {keys
                        .filter((key) => {
                          return staffAddNewStore.showFields.includes(key);
                        })
                        .map((field) => {
                          const colInfo = staffAddNewStore.inputTitles[field];
                          const colParams =
                            staffAddNewStore.fieldsParams[field];
                          switch (field) {
                            case "company":
                              return (
                                <div className={styles.company} key={field}>
                                  <SelectMulti
                                    name="company"
                                    label={colInfo.title}
                                    required={requiredFields.includes(field)}
                                    options={staffAddNewStore.companies}
                                    isMenuOpened={isCompaniesOpened}
                                    setIsMenuOpened={(isOpened) => {
                                      setIsCompaniesOpened(isOpened);
                                      if (isOpened) {
                                        changeOpenedWindows(null);
                                      }
                                    }}
                                    valueName="id"
                                  />
                                </div>
                              );
                            case "surname":
                              return (
                                <React.Fragment key={field}>
                                  <label className={styles.switch}>
                                    <Switch
                                      name="withoutSurname"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          requiredFields.includes(field) &&
                                            setRequiredFields(
                                              without(requiredFields, field)
                                            );

                                          setTimeout(() => {
                                            setFieldTouched("withoutSurname");
                                          }, 200);
                                        } else {
                                          !requiredFields.includes(field) &&
                                            setRequiredFields([
                                              field,
                                              ...requiredFields
                                            ]);

                                          setTimeout(() => {
                                            setFieldTouched("withoutSurname");
                                          }, 200);
                                        }
                                      }}
                                    />
                                    Внести иностранца только с именем
                                  </label>
                                  <AutoComplete
                                    values={values}
                                    initialValues={initialValues}
                                    name="fio"
                                    label="Автозаполнение ФИО"
                                    onChange={(e) => {
                                      handleChange(e);
                                      staffDaDataStore.getAutoData(
                                        "fioFind",
                                        "fio",
                                        e.target.value
                                      );
                                    }}
                                    array={staffDaDataStore.autoArrayFio}
                                    errorMessage={
                                      staffDaDataStore.errorMessageAutoFio
                                    }
                                    isLoading={isLoadingAutoData}
                                    cleanArray={staffDaDataStore.setAutoArray}
                                    keys={["surname", "name", "patronymic"]}
                                    disabled={Boolean(values["withoutSurname"])}
                                  />
                                  <Input
                                    name={field}
                                    label={colInfo.title}
                                    required={requiredFields.includes(field)}
                                    disabled={!values["withoutSurname"]}
                                  />
                                  <div className={styles.row}>
                                    {staffAddNewStore.showFields.includes(
                                      "name"
                                    ) ? (
                                      <Input
                                        name="name"
                                        label={
                                          staffAddNewStore.inputTitles["name"]
                                            .title
                                        }
                                        required={requiredFields.includes(
                                          "name"
                                        )}
                                        disabled={!values["withoutSurname"]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {staffAddNewStore.showFields.includes(
                                      "patronymic"
                                    ) ? (
                                      <Input
                                        name="patronymic"
                                        label={
                                          staffAddNewStore.inputTitles[
                                            "patronymic"
                                          ].title
                                        }
                                        required={requiredFields.includes(
                                          "patronymic"
                                        )}
                                        disabled={!values["withoutSurname"]}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            case "name": {
                              if (
                                !staffAddNewStore.showFields.includes("surname")
                              ) {
                                return (
                                  <React.Fragment key={field}>
                                    <AutoComplete
                                      values={values}
                                      initialValues={initialValues}
                                      name="fio"
                                      label="Автозаполнение ФИО"
                                      onChange={(e) => {
                                        handleChange(e);
                                        staffDaDataStore.getAutoData(
                                          "fioFind",
                                          "fio",
                                          e.target.value
                                        );
                                      }}
                                      array={staffDaDataStore.autoArrayFio}
                                      errorMessage={
                                        staffDaDataStore.errorMessageAutoFio
                                      }
                                      isLoading={isLoadingAutoData}
                                      cleanArray={staffDaDataStore.setAutoArray}
                                      keys={["surname", "name", "patronymic"]}
                                    />

                                    <Input
                                      name={field}
                                      label={colInfo.title}
                                      required={requiredFields.includes(field)}
                                      disabled
                                    />

                                    {staffAddNewStore.showFields.includes(
                                      "patronymic"
                                    ) ? (
                                      <Input
                                        name="patronymic"
                                        label={
                                          staffAddNewStore.inputTitles[
                                            "patronymic"
                                          ].title
                                        }
                                        required={requiredFields.includes(
                                          "patronymic"
                                        )}
                                        disabled
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              } else return;
                            }
                            case "patronymic": {
                              if (
                                !staffAddNewStore.showFields.includes(
                                  "surname"
                                ) &&
                                !staffAddNewStore.showFields.includes("name")
                              ) {
                                return (
                                  <React.Fragment key={field}>
                                    <AutoComplete
                                      values={values}
                                      initialValues={initialValues}
                                      name="fio"
                                      label="Автозаполнение ФИО"
                                      onChange={(e) => {
                                        handleChange(e);
                                        staffDaDataStore.getAutoData(
                                          "fioFind",
                                          "fio",
                                          e.target.value
                                        );
                                      }}
                                      array={staffDaDataStore.autoArrayFio}
                                      errorMessage={
                                        staffDaDataStore.errorMessageAutoFio
                                      }
                                      isLoading={isLoadingAutoData}
                                      cleanArray={staffDaDataStore.setAutoArray}
                                      keys={["surname", "name", "patronymic"]}
                                    />

                                    <Input
                                      name={field}
                                      label={colInfo.title}
                                      required={requiredFields.includes(field)}
                                      disabled
                                    />
                                  </React.Fragment>
                                );
                              } else return;
                            }

                            case "birthday":
                              return (
                                <div
                                  key={field}
                                  className={
                                    staffAddNewStore.showFields.includes(
                                      "phone"
                                    )
                                      ? styles.row
                                      : ""
                                  }
                                >
                                  <DatePickerField
                                    name={field}
                                    required={requiredFields.includes(field)}
                                    title={colInfo.title}
                                    placeholderVisible
                                    isCalendarOpened={
                                      nameCalendarOpened === field
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(field);
                                    }}
                                  />
                                  {staffAddNewStore.showFields.includes(
                                    "phone"
                                  ) ? (
                                    <PhoneInput
                                      name="phone"
                                      label={
                                        staffAddNewStore.inputTitles["phone"]
                                          .title
                                      }
                                      required={requiredFields.includes(
                                        "phone"
                                      )}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );

                            case "phone":
                              if (
                                !staffAddNewStore.showFields.includes(
                                  "birthday"
                                )
                              ) {
                                return (
                                  <div key={field}>
                                    <PhoneInput
                                      name="phone"
                                      label={
                                        staffAddNewStore.inputTitles["phone"]
                                          .title
                                      }
                                      required={requiredFields.includes(
                                        "phone"
                                      )}
                                    />
                                  </div>
                                );
                              } else return;

                            case "snils":
                              return (
                                <div key={field} className={styles.snils}>
                                  <label className={styles.switch}>
                                    <Switch
                                      name="hasSnils"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          staffAddNewStore.setErrorMessage({});

                                          requiredFields.includes(field) &&
                                            setRequiredFields(
                                              without(requiredFields, field)
                                            );

                                          setTimeout(() => {
                                            setFieldValue(field, "");
                                            setFieldTouched("hasSnils");
                                          }, 200);
                                        } else {
                                          !requiredFields.includes(field) &&
                                            setRequiredFields([
                                              field,
                                              ...requiredFields
                                            ]);

                                          setTimeout(() => {
                                            setFieldTouched("hasSnils");
                                          }, 200);
                                        }
                                      }}
                                    />
                                    Нет СНИЛС
                                  </label>

                                  {!values["hasSnils"] ? (
                                    <>
                                      <div
                                        className={styles.numberFormatInputs}
                                      >
                                        <NumberFormat
                                          required={requiredFields.includes(
                                            field
                                          )}
                                          format="###-###-### ##"
                                          mask="_"
                                          name="snils"
                                          value={values[field]}
                                          label={colInfo.title}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            handleChange(e);
                                            if (
                                              e.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                              ).length === 11
                                            ) {
                                              staffAddNewStore.checkSnils(
                                                e.target.value.replace(
                                                  /[^0-9]+/g,
                                                  ""
                                                )
                                              );
                                            } else if (
                                              e.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                              ).length === 0
                                            ) {
                                              staffAddNewStore.setErrorMessage(
                                                {}
                                              );
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          customInput={Input}
                                        />
                                      </div>
                                      {getValues(staffAddNewStore.errorsMessage)
                                        .length && !errors["snils"] ? (
                                        <div
                                          className={`${styles.error} ${
                                            !staffAddNewStore.errorsMessage
                                              .result
                                              ? styles.error_withLink
                                              : ""
                                          }`}
                                        >
                                          <Alert
                                            errors={
                                              staffAddNewStore.errorsMessage
                                                .message
                                            }
                                            errorIcon={
                                              staffAddNewStore.errorsMessage
                                                .result ? (
                                                <IconCircle />
                                              ) : null
                                            }
                                          />
                                          <LoadedComponent
                                            isLoading={isLoading}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              );

                            case "inn":
                              return (
                                <div key={field}>
                                  <NumberFormat
                                    format="############"
                                    name={field}
                                    label={colInfo.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values[field]}
                                    customInput={Input}
                                    required={requiredFields.includes(field)}
                                  />
                                </div>
                              );

                            case "pasp_date":
                              return (
                                <div
                                  key={field}
                                  className={
                                    staffAddNewStore.showFields.includes(
                                      "pasp_code"
                                    )
                                      ? styles.row
                                      : ""
                                  }
                                >
                                  <DatePickerField
                                    name={field}
                                    required={requiredFields.includes(field)}
                                    title={colInfo.title}
                                    placeholderVisible
                                    isCalendarOpened={
                                      nameCalendarOpened === field
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(field);
                                    }}
                                  />
                                  {staffAddNewStore.showFields.includes(
                                    "pasp_code"
                                  ) ? (
                                    <NumberFormat
                                      name="pasp_code"
                                      value={values["pasp_code"]}
                                      format="###-###"
                                      mask="_"
                                      placeholder="000-000"
                                      label={
                                        staffAddNewStore.inputTitles[
                                          "pasp_code"
                                        ].title
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      customInput={Input}
                                      required={requiredFields.includes(
                                        "pasp_code"
                                      )}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );

                            case "pasp_code":
                              if (
                                !staffAddNewStore.showFields.includes(
                                  "pasp_date"
                                )
                              ) {
                                return (
                                  <div key={field}>
                                    <NumberFormat
                                      name={field}
                                      value={values[field]}
                                      format="###-###"
                                      mask="_"
                                      placeholder="000-000"
                                      label={colInfo["title"]}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      customInput={Input}
                                      required={requiredFields.includes(field)}
                                    />
                                  </div>
                                );
                              } else return;

                            case "living_adress":
                              return (
                                <div key={field}>
                                  {staffAddNewStore.showFields.includes(
                                    "propisk"
                                  ) ||
                                  staffAddNewStore.showFields.includes(
                                    "temporary_registration"
                                  ) ? (
                                    <div className={styles.radioBtn}>
                                      {staffAddNewStore.showFields.includes(
                                        "propisk"
                                      ) ? (
                                        <label>
                                          <RadioButton
                                            name="address_match"
                                            value="propisk"
                                            onChange={(e) => {
                                              handleChange(e);
                                              setFieldValue(
                                                "living_adress",
                                                values["propisk"]
                                              );
                                            }}
                                          />
                                          Совпадает с пропиской
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      {staffAddNewStore.showFields.includes(
                                        "temporary_registration"
                                      ) ? (
                                        <label>
                                          <RadioButton
                                            name="address_match"
                                            value="temporary_registration"
                                            onChange={(e) => {
                                              handleChange(e);
                                              setFieldValue(
                                                "living_adress",
                                                values["temporary_registration"]
                                              );
                                            }}
                                          />
                                          Совпадает с временной регистрацией
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      <label>
                                        <RadioButton
                                          name="address_match"
                                          value="no_match"
                                          onChange={handleChange}
                                        />
                                        Нет совпадений
                                      </label>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <Input
                                    name={field}
                                    onChange={handleChange}
                                    label={colInfo["title"]}
                                    required={requiredFields.includes(field)}
                                    disabled={
                                      values["address_match"] === "propisk" ||
                                      values["address_match"] ===
                                        "temporary_registration"
                                    }
                                  />
                                </div>
                              );
                          }

                          if (field.includes("comment")) {
                            return (
                              <Textarea
                                key={field}
                                name={field}
                                value={values[field] as string}
                                label={colInfo.title}
                                placeholder={colInfo.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={requiredFields.includes(field)}
                                commentCol={
                                  staffAddNewStore.inputTitles.comment
                                }
                              />
                            );
                          }
                          if (colParams.isvariable) {
                            return (
                              <div key={field}>
                                <Select
                                  name={field}
                                  isFloating
                                  title={colInfo.title}
                                  options={
                                    colParams.variable as {
                                      [key: string]: {
                                        [key: string]: string;
                                        title: string;
                                      };
                                    }
                                  }
                                  onBlur={handleBlur}
                                  valueName="title"
                                  required={requiredFields.includes(field)}
                                />
                              </div>
                            );
                          }

                          if (colInfo)
                            switch (colInfo["type"]) {
                              case "date":
                              case "timestamp":
                                return (
                                  <DatePickerField
                                    key={field}
                                    name={field}
                                    title={colInfo["title"]}
                                    isCalendarOpened={
                                      nameCalendarOpened === field
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows(field);
                                    }}
                                    required={requiredFields.includes(field)}
                                  />
                                );
                              case "bool":
                                return (
                                  <label
                                    key={field}
                                    className={`${styles.switch} ${
                                      !values[field]
                                        ? styles.switchOff
                                        : styles.switchOn
                                    }`}
                                  >
                                    <Switch name={field} />
                                    {colInfo["title"]}
                                  </label>
                                );
                            }

                          return (
                            <div key={field}>
                              <Input
                                name={field}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (
                                    field === "propisk" &&
                                    values["address_match"] === "propisk"
                                  ) {
                                    setFieldValue(
                                      "living_adress",
                                      e.target.value
                                    );
                                  }
                                  if (
                                    field === "temporary_registration" &&
                                    values["address_match"] ===
                                      "temporary_registration"
                                  ) {
                                    setFieldValue(
                                      "living_adress",
                                      e.target.value
                                    );
                                  }
                                }}
                                label={colInfo["title"]}
                                required={requiredFields.includes(field)}
                              />
                            </div>
                          );
                        })}

                      <div className={styles.switches}>
                        <label className={styles.switch}>
                          <Switch
                            name="withConviction"
                            onChange={(event) => {
                              if (event.target.checked) {
                                setFieldValue("conviction", [{}]);
                                setIsConvictionField(true);
                              } else {
                                setIsConvictionField(false);
                                setFieldValue("conviction", []);
                              }
                              setTimeout(() => {
                                setFieldTouched("withConviction");
                              }, 200);
                            }}
                          />
                          Есть судимость
                        </label>
                        {values["withConviction"] ? (
                          <>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className={styles.title}>Судимость</div>
                              <Button
                                type="button"
                                theme={ButtonTheme.CLEAR}
                                onClick={() => {
                                  setFieldValue("conviction", [
                                    ...values["conviction"],
                                    {}
                                  ]);
                                }}
                                id="StaffAddNew_addConviction"
                              >
                                Добавить еще
                                <IconAdd />
                              </Button>
                            </div>
                            <div>
                              {values["conviction"].map((element, i) => {
                                return (
                                  <div
                                    key={`conviction_${i}`}
                                    className={styles.convictionItem}
                                  >
                                    <div>{i + 1}.</div>
                                    <div className={styles.convictionInputs}>
                                      <Select
                                        name={`conviction.${i}.article`}
                                        isFloating
                                        title="Статья, по которой была судимость"
                                        options={
                                          staffAddNewStore
                                            .convictionsSelectsList["article"]
                                        }
                                        onBlur={handleBlur}
                                        required
                                      />
                                      <DatePickerField
                                        name={`conviction.${i}.date_start`}
                                        title="Дата судимости"
                                        isCalendarOpened={
                                          nameCalendarOpened ===
                                          `conviction.${i}.date_start`
                                        }
                                        setIsCalendarOpened={() => {
                                          changeOpenedWindows(
                                            `conviction.${i}.date_start`
                                          );
                                        }}
                                        required
                                      />
                                      <Select
                                        name={`conviction.${i}.punishment`}
                                        isFloating
                                        title="Наказание по статье"
                                        options={
                                          staffAddNewStore
                                            .convictionsSelectsList[
                                            "punishment"
                                          ]
                                        }
                                        onBlur={handleBlur}
                                        valueName="title"
                                      />
                                      <Select
                                        name={`conviction.${i}.term_type`}
                                        isFloating
                                        title="Тип срока"
                                        options={
                                          staffAddNewStore
                                            .convictionsSelectsList["term_type"]
                                        }
                                        onBlur={handleBlur}
                                        valueName="title"
                                      />
                                      <div className={styles.comment}>
                                        <Textarea
                                          name={`conviction.${i}.comment`}
                                          value={element["comment"]}
                                          label="Комментарий"
                                          placeholder="Комментарий"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          commentCol={
                                            staffAddNewStore.inputTitles.comment
                                          }
                                        />
                                        <div
                                          className={
                                            styles.btnsBlockForConvictions
                                          }
                                        >
                                          <ButtonsGroupForSettings
                                            cancelBtnTitle="Удалить"
                                            cancelBtnOnClick={() => {
                                              if (
                                                values["conviction"].length > 1
                                              ) {
                                                values["conviction"].splice(
                                                  i,
                                                  1
                                                );
                                                setFieldValue(
                                                  "conviction",
                                                  values["conviction"]
                                                );
                                              } else {
                                                [
                                                  `conviction.${i}.article`,
                                                  `conviction.${i}.date_start`,
                                                  `conviction.${i}.punishment`,
                                                  `conviction.${i}.term_type`,
                                                  `conviction.${i}.comment`
                                                ].forEach((name) =>
                                                  setFieldValue(name, "")
                                                );
                                                setTimeout(() => {
                                                  setFieldTouched("conviction");
                                                }, 200);
                                              }
                                            }}
                                            cancelBtnImg={<IconClose />}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <label className={styles.switch}>
                          <Switch name="add_more" />
                          После нажатия кнопки “Создать” добавить ещё
                        </label>
                      </div>

                      <div className={styles.btnsBlock}>
                        <div
                          className={classNames("", {
                            [styles.tooltip]: !isValid || !dirty
                          })}
                          data-tooltip="Не все обязательные поля заполнены"
                        >
                          <ButtonsGroupForSettings
                            saveBtnTitle="Создать"
                            saveBtnDisabled={
                              !dirty ||
                              !isValid ||
                              (staffAddNewStore.errorsMessage?.message &&
                                "body" in
                                  staffAddNewStore.errorsMessage.message)
                            }
                            saveBtnOnClick={handleSubmit}
                          />
                        </div>
                        <ButtonsGroupForSettings
                          addBtnTitle="Очистить форму"
                          addBtnImg={<IconClose />}
                          addBtnDisabled={!dirty}
                          addBtnOnClick={() => {
                            setRequiredFields(staffAddNewStore.requiredFields);
                            handleReset();
                            setErrors({});
                            setTouched({});
                            staffAddNewStore.resetMassEmployees();
                          }}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
            {isOpenSuccessModal ? (
              <Modal
                type="success"
                title="Сотрудник успешно добавлен"
                show={isOpenSuccessModal}
                onHide={() => {
                  setIsOpenSuccessModal(false);
                }}
                successIcon
                direction="row"
              />
            ) : (
              ""
            )}
          </>
        </LoadedComponent>
      </div>
    </ErrorBoundary>
  );
};

export default observer(StaffAddNew);
