import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Col } from "./types/Col";
import { TableParams } from "./types/TableParams";

type InputTitles = {
  [key: string]: {
    title: string | null;
  };
};

type DoubleFieldsResult = {
  name: string | null;
  surname: string | null;
  patronymic: string | null;
  uid: number | null;
  birthday: string | null;
};

export type DoubleFieldsRequestData = {
  val: string;
  touched: boolean;
  error: boolean;
};

type ConvictionType = Record<string, string>;

type SpecificStaffProps = {
  add_more: number;
  hasSnils: number;
  withoutSurname: number;
  withConviction: number;
  conviction: ConvictionType[];
};

type AdditionalStaffProps = Record<string, string | number | ConvictionType[]>;

type NewStaffObj = Partial<SpecificStaffProps & AdditionalStaffProps>;

export default class StaffAddNewStore {
  newStaffObj: NewStaffObj = {};
  inputTitles: InputTitles;
  fieldsParams: { [key: string]: TableParams } = {};
  showFields: string[] = [];
  requiredFields: string[] = ["snils"];
  isLoading = false;
  isLoadingForField = false;
  error = false;
  errorsMessage: Partial<Errors> = {};

  massEmployees: {
    uid: string | null;
    fio: string | null;
    birthday: string | null;
  }[] = [];
  companies: { [key: string]: { id: string | null; title: string | null } } =
    {};
  convictionsSelectsList: { [key: string]: Col["directory"] } = {};

  getCompaniesList = async (): Promise<{
    [key: string]: { id: string | null; title: string | null };
  }> => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: { id: string | null; title: string | null };
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList",
        select: ["id", "title"]
      });
      return data["result"] !== -1 ? data["result"] : {};
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getInputsTitles = async (): Promise<InputTitles> => {
    try {
      const data: ApiResponse<InputTitles> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableCols"
        });
      data.result.without_edu && delete data.result.without_edu;
      return data["result"];
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelectsLists = async (): Promise<{ [key: string]: TableParams }> => {
    try {
      const data: ApiResponse<{ [key: string]: TableParams } | number> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableParams"
        });

      if (typeof data.result !== "number") {
        return data["result"];
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getConvictionsSelectsList = async () => {
    try {
      const data: ApiResponse<boolean> & {
        [key: string]: Col["directory"];
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "conviction",
        method: "getSelects"
      });

      if (data.result) {
        return data;
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setFormFields = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    let selectsLists: { [key: string]: TableParams },
      inputTitles: InputTitles,
      companies: { [key: string]: { id: string | null; title: string | null } },
      convictionsSelectsList: {
        [key: string]: Col["directory"] | boolean;
      };

    if (!this.inputTitles) {
      [inputTitles, selectsLists, companies, convictionsSelectsList] =
        await Promise.all([
          this.getInputsTitles(),
          this.getSelectsLists(),
          this.getCompaniesList(),
          this.getConvictionsSelectsList()
        ]);
    }

    const modifiedObj: { [key: string]: string | null } = {};

    runInAction(() => {
      this.newStaffObj = {};
      for (const prop in inputTitles) {
        modifiedObj[prop] = inputTitles[prop]["title"];
        switch (prop) {
          case "snils":
            this.newStaffObj["hasSnils"] = 0;
            this.newStaffObj["snils"] = "";
            break;
          case "sud":
            this.newStaffObj["withConviction"] = 0;
            break;
          default:
            switch (inputTitles[prop]["type"]) {
              case "bool":
                this.newStaffObj[prop] = 0;
                break;
              default:
                this.newStaffObj[prop] = "";
            }
        }
      }
      this.newStaffObj["autoFIO"] = "";
      this.newStaffObj["withoutSurname"] = 0;
      this.newStaffObj["add_more"] = 0;
      this.newStaffObj["address_match"] = "propisk";
      this.newStaffObj["education"] = "";
      this.newStaffObj["conviction"] = [];

      Object.entries(selectsLists).forEach(([key, value]) => {
        if (value.add_show === "on") {
          this.showFields.push(key);
        }
        if (value.required) {
          this.requiredFields.push(key);
        }

        if (key === "surname") {
          !this.requiredFields.includes("surname") &&
            this.requiredFields.push("surname");
        }
      });

      Object.entries(convictionsSelectsList).forEach(([key, value]) => {
        if (typeof value === "object") {
          const newDirectory = {};
          Object.entries(value).forEach(([numeric, item]) => {
            newDirectory[numeric] = {
              ...item,
              newname: item["id"] ? item["id"] : item.title,
              title:
                key === "article" && item?.["number"]
                  ? `ст.${item["number"]} ${
                      item["part"] ? `ч.${item["part"]}` : ""
                    } ${item["title"]}`
                  : item["title"]
            };
          });
          this.convictionsSelectsList[key] = newDirectory;
        }
      });

      this.companies = companies;
      this.inputTitles = inputTitles;
      this.fieldsParams = selectsLists;

      this.isLoading = false;
    });
  };

  getDoubleFields = async (obj: {
    name: string;
    surname: string;
    birthday: string;
  }) => {
    const value = `${obj.surname}${obj.name}${obj.birthday || ""}`;

    const data: ApiResponse<DoubleFieldsResult | number> =
      await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        currentClass: "staff",
        method: "getList",
        select: ["id", "uid", "surname", "name", "patronymic", "birthday"],
        where: {
          "LOWER(CONCAT(`surname`,`name`,`birthday`))": {
            value: value,
            comparison: "LIKE"
          }
        }
      });
    if (data.result !== -1) {
      const modifiedArray: {
        uid: string | null;
        fio: string | null;
        birthday: string | null;
      }[] = Object.values(data.result).map((item: DoubleFieldsResult) => {
        return {
          uid: `ТН ${item.uid}`,
          fio: `${item.surname} ${item.name} ${item.patronymic}`,
          birthday: item.birthday
        };
      });

      runInAction(() => {
        this.massEmployees = modifiedArray;
      });
    } else {
      runInAction(() => {
        this.massEmployees = [];
      });
    }
  };

  resetMassEmployees = () => {
    this.massEmployees = [];
  };

  addOneStaff = async (newStaffObj: {
    [key: string]:
      | string
      | number
      | { [key: string]: string }[]
      | { [key: string]: string | number };
  }): Promise<string> => {
    runInAction(() => {
      this.isLoading = true;
    });

    const formData: { [key: string]: string | File } = {};
    let companies: string[];
    for (const [key, value] of Object.entries(newStaffObj)) {
      if (value) {
        switch (key) {
          case "company":
            companies = Object.values(value);
            for (let i = 0; i < companies.length; i++) {
              formData[`company[${i}]`] = companies[i];
            }
            break;
          case "phone":
            formData["phone[0][number]"] = String(value);
            formData["phone[0][comment]"] = "Основной";
            break;
          case "withConviction":
            formData[key] = value ? "on" : String(value);
            break;
          case "conviction":
            (value as { [key: string]: string }[]).forEach((item, i) => {
              Object.entries(item).forEach(([title, val]) => {
                formData[`conviction[${i}][${title}]`] = val;
              });
            });
            break;
          case "address_match":
            break;
          default:
            formData[key] = String(value);
        }
      }
    }

    try {
      const data: ApiResponse<string> & { staff_id: string | null } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          currentClass: "staff",
          method: "add",
          body: formData
        });

      if (data["code"] !== 200) {
        runInAction(() => {
          this.error = true;
        });
      } else {
        return data.staff_id;
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  checkSnils = async (value: string) => {
    this.isLoadingForField = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "checkSnils",
        params: {
          snils: value
        }
      });

      runInAction(() => {
        this.errorsMessage = data;
        this.isLoadingForField = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForField = false;
      });
    }
  };

  setErrorMessage = (value: Partial<Errors>) => {
    this.errorsMessage = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
