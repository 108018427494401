import styles from "./awardsWindow.module.scss";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import validationEdit from "./validationEdit";
import validationAdd from "./validationAdd";
import WindowBody from "./WindowBody";
import History from "./History";
import { useStores } from "stores";
import FormWindow from "shared/ui/FormWindow";
import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Award } from "stores/StaffModule/types/Award";
import { isEmpty } from "lodash";
import WindowTabs from "./WindowTabs";
import { classNames } from "shared/utils/helpers/classNames";

const AwardsWindow = () => {
  const { staffOneAwardsStore } = useStores();

  const currentAward: Award =
    staffOneAwardsStore.awards[staffOneAwardsStore.openedAward];

  return (
    <>
      {staffOneAwardsStore.openedWindowType && (
        <Formik
          initialValues={{
            staff_id: staffOneAwardsStore.staffInfo.id,
            award_id: currentAward?.id,
            award_type: currentAward?.type,
            award_start:
              staffOneAwardsStore.openedWindowType === "add"
                ? null
                : currentAward?.award_start,
            new_award: "",
            withoutAwards: staffOneAwardsStore.withoutAwards
          }}
          //   TODO: разобраться с yup и объединить валидацию в один файл
          validationSchema={
            staffOneAwardsStore.openedWindowType === "edit"
              ? validationEdit
              : validationAdd
          }
          onSubmit={() => {
            return;
          }}
          enableReinitialize
        >
          {({ dirty, handleReset, isValid, values }) => {
            const handleCancel = () => {
              handleReset();
              if (staffOneAwardsStore.openedWindowType === "edit") {
                staffOneAwardsStore.setOpenedWindowType("view");
              }
            };

            const handleDelete = async () => {
              await staffOneAwardsStore.deleteAward(
                values.staff_id,
                currentAward.id,
                currentAward?.type_title
              );
              if (isEmpty(staffOneAwardsStore.errorsMessage)) {
                setTimeout(() => {
                  staffOneAwardsStore.clearDeletedAward();
                }, 3000);
              }
            };

            const handleSave = async () => {
              if (staffOneAwardsStore.openedWindowType === "add") {
                await staffOneAwardsStore.addAward(
                  values.staff_id,
                  values.new_award,
                  values.award_start
                );
              }
              if (staffOneAwardsStore.openedWindowType === "edit") {
                await staffOneAwardsStore.updateAward(
                  values.staff_id,
                  values.award_id,
                  values.award_start
                );
              }
              if (staffOneAwardsStore.openedWindowType === "view") {
                staffOneAwardsStore.setOpenedWindowType("edit");
              }
            };

            return (
              <FormWindow
                title={
                  staffOneAwardsStore.openedWindowType !== "add"
                    ? "Награда"
                    : "Добавление награды сотруднику"
                }
                subtitle={`${staffOneAwardsStore.staffInfo.surname} ${
                  staffOneAwardsStore.staffInfo.name
                } ${staffOneAwardsStore.staffInfo.patronymic || ""}`}
                setOpenWindow={() => {
                  staffOneAwardsStore.setOpenedWindowType("");
                  staffOneAwardsStore.setWithoutAwards(false);
                }}
                optionalCloseFunc={handleCancel}
                isLoadingForModal={
                  staffOneAwardsStore.isLoadingForModal ||
                  staffOneAwardsStore.isLoading
                }
                saveBtnTitle={
                  staffOneAwardsStore.openedWindowType === "edit"
                    ? "Сохранить изменения"
                    : staffOneAwardsStore.openedWindowType === "view"
                    ? "Редактировать"
                    : "Добавить"
                }
                saveBtnOnClickFunc={handleSave}
                saveBtnDisabledValue={
                  !isEmpty(staffOneAwardsStore.errorsMessage) ||
                  (staffOneAwardsStore.openedWindowType === "edit" && !dirty) ||
                  (staffOneAwardsStore.openedWindowType === "add" && !dirty) ||
                  (staffOneAwardsStore.openedWindowType === "add" &&
                    dirty &&
                    !isValid)
                }
                addBtnTitle={
                  staffOneAwardsStore.openedWindowType === "edit" ||
                  staffOneAwardsStore.openedWindowType === "add"
                    ? "Отмена"
                    : undefined
                }
                addBtnOnClickFunc={handleCancel}
                addBtnImg={<IconClose />}
                deleteBtnTitle={
                  staffOneAwardsStore.openedWindowType === "view"
                    ? "Удалить награду"
                    : undefined
                }
                deleteBtnOnClick={
                  staffOneAwardsStore.openedWindowType !== "edit"
                    ? handleDelete
                    : undefined
                }
                deleteBtnImg={<IconClose />}
                deleteConfirmationModalTitle={`Вы уверены, что хотите удалить награду "${currentAward?.type_title}"?`}
                unsavedChangesModalSubtitle={
                  "Вы уверены, что хотите закрыть окно без сохранения данных?"
                }
                saveSuccessModal={staffOneAwardsStore.success}
                successMessageTitle={{
                  save:
                    staffOneAwardsStore.openedWindowType === "add"
                      ? `Награда "${
                          staffOneAwardsStore.allAwards[
                            staffOneAwardsStore.openedAward
                          ]?.title
                        }" добавлена`
                      : "Изменения сохранены",
                  delete: `Награда "${staffOneAwardsStore.deletedAward}" удалена`
                }}
                onSuccessModalClose={() => {
                  staffOneAwardsStore.setSuccess(false);
                }}
                isNotHaveButtons={
                  staffOneAwardsStore.withoutAwards ||
                  !staffOneAwardsStore.access
                }
                withHistory={!staffOneAwardsStore.withoutAwards}
                historyTitle="История изменений"
                historyContent={
                  staffOneAwardsStore.openedWindowType !== "add" ? (
                    <History history={currentAward?.history} isCollapsible />
                  ) : (
                    ""
                  )
                }
                isScroll
              >
                <>
                  {staffOneAwardsStore.openedWindowType !== "add" ? (
                    <div
                      className={classNames(styles.awardDetails, {
                        [styles.awardDetailsWithoutBorder]:
                          staffOneAwardsStore.openedWindowType !== "edit"
                      })}
                    >
                      <p className={styles.title}>Группа</p>

                      {staffOneAwardsStore.openedWindowType === "edit" ? (
                        <div className={styles.group}>
                          {
                            staffOneAwardsStore.awardGroups[
                              currentAward?.custom?.group
                            ]?.title
                          }
                        </div>
                      ) : (
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                          <WindowTabs />
                        </ErrorBoundary>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <WindowBody currentAward={currentAward} />
                  </ErrorBoundary>
                </>
              </FormWindow>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default observer(AwardsWindow);
