import styles from "./staffOne.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import LoadedComponent from "widgets/LoadedComponent";
import StaffOneInfo from "./StaffOneInfo";
import StaffOneInfoStatuses from "./StaffOneInfoStatuses";
import StaffOneInfoComments from "./StaffOneInfoComments";
import StaffOneInfoWorkedShifts from "./StaffOneInfoWorkedShifts";
import StaffOneInfoAwards from "./StaffOneInfoAwards";
import StaffOneInfoHistory from "./StaffOneInfoHistory";
import StaffOneInfoSafetyWork from "./StaffOneInfoSafetyWork";
import StaffOneInfoConvictions from "./StaffOneInfoConvictions";
import StaffOneInfoFinance from "./StaffOneInfoFinance";
import StaffOneInfoMain from "./StaffOneInfoMain";
import StaffOneInfoPremiumLevel from "./StaffOneInfoPremiumLevel";

import { cloneDeep, difference, without } from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import StaffOneBlocked from "./StaffOneBlocked";
import Collapsible from "shared/ui/Collapsible";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import CollapsibleButton from "shared/ui/CollapsibleButton";

const StaffOneInformation = () => {
  const { menuStore, staffOneStore, userStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);
  const [openedWidgets, setOpenedWidgets] = useState<{
    leftCol: string[];
    rightCol: string[];
  }>({ leftCol: [], rightCol: [] });

  const { id } = useParams();
  const componentRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (menuStore.scroll.scrollTop) {
      menuStore.scrollbarRef.current?.view?.scroll({
        top: 0,
        left: 0
      });
    }
  }, []);

  useEffect(() => {
    staffOneStore.setSelectedOneForInfo(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaff");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}`
    });
  }, [id]);

  useEffect(() => {
    setOpenedWidgets(
      localStorage.getItem("openedWidgets") &&
        JSON.parse(localStorage.getItem("openedWidgets"))[userStore.user_id]
        ? JSON.parse(localStorage.getItem("openedWidgets"))[userStore.user_id]
        : { leftCol: [], rightCol: [] }
    );
  }, []);

  useEffect(() => {
    const allUsersWidgetsFromStorage = localStorage.getItem("openedWidgets")
      ? JSON.parse(localStorage.getItem("openedWidgets"))
      : null;

    if (allUsersWidgetsFromStorage) {
      allUsersWidgetsFromStorage[userStore.user_id] = openedWidgets;
      localStorage.setItem(
        "openedWidgets",
        JSON.stringify(allUsersWidgetsFromStorage)
      );
    } else {
      localStorage.setItem(
        "openedWidgets",
        JSON.stringify({
          [userStore.user_id]: openedWidgets
        })
      );
    }
  }, [openedWidgets]);

  useEffect(() => {
    setTimeout(() => {
      if (menuStore.scroll.scrollTop >= 255 && !isScroll) {
        setIsScroll(true);
      } else if (menuStore.scroll.scrollTop < 80 && isScroll) {
        setIsScroll(false);
      }
    }, 200);
  }, [menuStore.scroll.scrollTop]);

  const changeOpenedWidgets = (
    col: "rightCol" | "leftCol",
    type: "add" | "delete",
    widgets: string[]
  ) => {
    const newOpenedWidgets = cloneDeep(openedWidgets);
    if (type === "add") {
      widgets.forEach(
        (widget) =>
          !newOpenedWidgets[col].includes(widget) &&
          newOpenedWidgets[col].push(widget)
      );
    } else {
      widgets.forEach(
        (widget) =>
          (newOpenedWidgets[col] = without(newOpenedWidgets[col], widget))
      );
    }
    setOpenedWidgets(newOpenedWidgets);
  };

  const getHeightOfMainInfo = () => {
    const statusesLength = staffOneStore.widgetMetrics[id]?.building_metrics
      ? getValues(staffOneStore.widgetMetrics[id]?.building_metrics).length
      : 0;

    const metriсsLength = staffOneStore.widgetMetrics[id]?.classic_metrics
      ? (getValues(staffOneStore.widgetMetrics[id]?.classic_metrics).filter(
          (block) => block.colspan === 1
        ).length
          ? Math.ceil(
              getValues(
                staffOneStore.widgetMetrics[id]?.classic_metrics
              ).filter((block) => block.colspan === 1).length / 2
            )
          : 0) +
        getValues(staffOneStore.widgetMetrics[id]?.classic_metrics).filter(
          (block) => block.colspan === 2
        ).length +
        // т.к. убрали метрику Вычета УЦ - добавлем принудительно 1, позже исправить
        1
      : 0;

    if (statusesLength || metriсsLength) {
      // Высота блока  + количество блоков + отступы между блоками
      // Для блока статусов блоки с отступами + ТН
      return 61.19 * statusesLength + statusesLength * 12 + 12 + 100 >
        56.93 * metriсsLength + (metriсsLength - 1) * 10 + 20 + 40
        ? `${61.19 * statusesLength + (statusesLength - 1) * 12 + 12 + 100}px`
        : `${56.93 * metriсsLength + (metriсsLength - 1) * 10 + 20 + 40}px`;
    }
  };

  const widgetsList = without(
    staffOneStore.widgetsOrder,
    ...difference(staffOneStore.widgetsOrder, menuStore.staffWidgetList)
  );

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoading[id]}
      error={staffOneStore.error[id]}
      errorMessage={staffOneStore.errorText[id]}
    >
      <>
        {getKeys(staffOneStore.info).length ? (
          !staffOneStore.info["locked"] ? (
            <>
              <div className={styles.staffOne}>
                <div>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <StaffOneInfoMain
                      scrollTop={isScroll}
                      maxHeight={getHeightOfMainInfo()}
                    />
                  </ErrorBoundary>

                  <div
                    className={classNames("", {
                      [styles.main]: !isScroll,
                      [styles.mainWithHeight]: isScroll
                    })}
                  >
                    <div className={styles.mainCol}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <StaffOneInfo
                          openedWidgets={openedWidgets?.leftCol}
                          componentRef={componentRef}
                          changeOpenedWidgets={changeOpenedWidgets}
                          isScroll={isScroll}
                          top={getHeightOfMainInfo()}
                        />
                      </ErrorBoundary>
                    </div>
                    <div className={styles.mainCol}>
                      <div
                        className={classNames("", {
                          [styles.openedAllWidgets]: !isScroll,
                          [styles.openedAllWidgetsMiniTop]: isScroll
                        })}
                        style={{
                          top: !isScroll ? getHeightOfMainInfo() : ""
                        }}
                      >
                        <CollapsibleButton
                          onClick={() => {
                            changeOpenedWidgets(
                              "rightCol",
                              openedWidgets?.rightCol?.length
                                ? "delete"
                                : "add",
                              widgetsList
                            );
                          }}
                          openedAllWidgets={openedWidgets?.rightCol?.length}
                        />
                      </div>
                      {widgetsList.map((group) => {
                        const handleSetOpenedWidgets = () => {
                          changeOpenedWidgets(
                            "rightCol",
                            openedWidgets?.rightCol?.includes(group)
                              ? "delete"
                              : "add",
                            [group]
                          );
                        };
                        return (
                          <Collapsible
                            key={group}
                            onClick={handleSetOpenedWidgets}
                            title={(() => {
                              switch (group) {
                                case "building_staff_events":
                                  return "Статус на объекте";
                                case "comments":
                                  return "Комментарии";
                                case "workshift":
                                  return "Отработано смен";
                                case "awards":
                                  return "Награды";
                                case "conviction":
                                  return "Судимости";
                                case "safety_work":
                                  return "Охрана труда";
                                case "history":
                                  return "История";
                                case "salary":
                                  return "Финансы";
                                case "premium_level":
                                  return "ПОЛАТИ Бонус";
                              }
                            })()}
                            isOpenedField={openedWidgets?.rightCol?.includes(
                              group
                            )}
                            className={{
                              headerContainer: classNames("", {
                                [styles.widgetHeader]: !isScroll,
                                [styles.widgetHeaderMiniTop]: isScroll
                              }),
                              headerTitle: styles.title
                            }}
                            linkButton={
                              <div
                                className={classNames("", {
                                  [styles.widgetBtn]:
                                    !openedWidgets?.rightCol?.includes(group),
                                  [styles.widgetBtnNoMargin]:
                                    openedWidgets?.rightCol?.includes(group)
                                })}
                                id={`StaffOneInformation_${group}`}
                                onClick={() => {
                                  navigate(
                                    `./${
                                      group === "building_staff_events"
                                        ? "statuses"
                                        : group
                                    }`
                                  );
                                }}
                              >
                                Перейти в виджет
                              </div>
                            }
                            isStickyHeader
                          >
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                              {(() => {
                                switch (group) {
                                  case "building_staff_events":
                                    return <StaffOneInfoStatuses />;
                                  case "comments":
                                    return <StaffOneInfoComments />;
                                  case "workshift":
                                    return <StaffOneInfoWorkedShifts />;
                                  case "awards":
                                    return <StaffOneInfoAwards />;
                                  case "conviction":
                                    return <StaffOneInfoConvictions />;
                                  case "safety_work":
                                    return <StaffOneInfoSafetyWork />;
                                  case "history":
                                    return <StaffOneInfoHistory />;
                                  case "salary":
                                    return <StaffOneInfoFinance />;
                                  case "premium_level":
                                    return <StaffOneInfoPremiumLevel />;
                                }
                              })()}
                            </ErrorBoundary>
                          </Collapsible>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {/* Блок для печати страницы, временно закомментирован */}
              {/* <div className={styles.mainForPrint}>
              <div ref={componentRef}>
                <StaffOneInfoMain scrollTop={scroll?.scrollTop} />
                <Scrollbars
                  style={{ width: "100%" }}
                  autoHeight
                  // для вычисления максимальной высоты карточки:
                  // 50px - хедер, второе значение - блок с фото
                  autoHeightMax={`calc(${maxHeight} - ${headerHeight} - ${
                    scroll?.scrollTop ? "85px" : "250px"
                  })`}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  onScrollFrame={(e) => handleScroll(e)}
                >
                  <div className={styles.main}>
                    <div className={styles.mainCol}>
                      <StaffOneInfo
                        openedWidgets={openedWidgets?.leftCol}
                        componentRef={componentRef}
                      />
                    </div>
                    <div className={styles.mainCol}>
                      <div className={styles.openedAllWidgets}>
                        <div
                          id={`StaffOneInformation_openedAllWidgetsRight_${id}`}
                          className={`${styles.openedAllWidgets__text} ${
                            openedWidgets?.rightCol?.length
                              ? styles.openedAllWidgets__text_active
                              : ""
                          }`}
                          onClick={() => {
                            changeOpenedWidgets(
                              id,
                              "rightCol",
                              openedWidgets?.rightCol?.length
                                ? "delete"
                                : "add",
                              [
                                "building_staff_events",
                                "salary",
                                "workshift",
                                "safety_work",
                                "conviction",
                                "awards",
                                "history",
                                "comments"
                              ]
                            );
                          }}
                        >
                          {openedWidgets?.rightCol?.length
                            ? "Свернуть всё"
                            : "Развернуть всё"}
                        </div>
                      </div>
                      {[
                        "building_staff_events",
                        "salary",
                        "workshift",
                        "safety_work",
                        "conviction",
                        "awards",
                        "history",
                        "comments"
                      ].map((group) => {
                        return (
                          <div key={group} className={styles.dashedLine}>
                            <div
                              id={`staffOneInfoOne_showInfo_${group}`}
                              onClick={() => {
                                changeOpenedWidgets(
                                  id,
                                  "rightCol",
                                  openedWidgets?.rightCol?.includes(group)
                                    ? "delete"
                                    : "add",
                                  [group]
                                );
                              }}
                              className={`${styles.blind} ${
                                !openedWidgets?.rightCol?.includes(group)
                                  ? styles.blind_withoutPadding
                                  : ""
                              }`}
                            >
                              <IconCollapseStroke
                                className={
                                  !openedWidgets?.rightCol?.includes(group)
                                    ? styles.blind__icon_close
                                    : styles.blind__icon_show
                                }
                              />
                              <p>
                                {(() => {
                                  switch (group) {
                                    case "building_staff_events":
                                      return "Статус на объекте";
                                    case "comments":
                                      return "Комментарии";
                                    case "workshift":
                                      return "Отработано смен";
                                    case "awards":
                                      return "Награды";
                                    case "conviction":
                                      return "Судимости";
                                    case "safety_work":
                                      return "Охрана труда";
                                    case "history":
                                      return "История";
                                    case "salary":
                                      return "Финансы";
                                  }
                                })()}
                              </p>
                              <div
                                className={
                                  !openedWidgets?.rightCol?.includes(group)
                                    ? styles.blind__gradientLine_gray
                                    : styles.blind__gradientLine
                                }
                              />
                            </div>
                            {(() => {
                              if (openedWidgets?.rightCol?.includes(group)) {
                                return (
                                  <div className={styles.widgetContainer}>
                                    {(() => {
                                      switch (group) {
                                       case "building_staff_events":
                                          return <StaffOneInfoStatuses />;
                                        case "comments":
                                          return <StaffOneInfoComments />;
                                        case "workshift":
                                          return <StaffOneInfoWorkedShifts />;
                                        case "awards":
                                          return <StaffOneInfoAwards />;
                                        case "conviction":
                                          return <StaffOneInfoConvictions />;
                                        case "safety_work":
                                          return <StaffOneInfoSafetyWork />;
                                        case "history":
                                          return <StaffOneInfoHistory />;
                                        case "salary":
                                          return <StaffOneInfoFinance />;
                                      }
                                    })()}
                                  </div>
                                );
                              }
                            })()}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div> */}
            </>
          ) : (
            <StaffOneBlocked />
          )
        ) : (
          ""
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInformation);
