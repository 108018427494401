import styles from "./staffImportTable.module.scss";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { StaffImportTableForFormik } from "stores/StaffModule/types/StaffImportTable";
import { Table } from "react-bootstrap";
import StaffImportTableBody from "./StaffImportTableBody";
import StaffImportTableTitles from "./StaffImportTableTitles";
import StaffImportTableLoader from "./StaffImportTableLoader";

const StaffImportTable = () => {
  const { staffImportStore, menuStore } = useStores();

  const { values } = useFormikContext<StaffImportTableForFormik>();

  return (
    <>
      <Table
        className={classNames(styles.table, {
          [styles.isLoading]: staffImportStore.isLoadingDuplicateCheck
        })}
      >
        <thead>
          <tr className={styles.header}>
            <th>{values.staff.length}</th>
            <th>{values.staff.filter((item) => item.select).length}</th>
            <th
              colSpan={4}
              className={classNames(styles.colspan, {
                [styles.colspan__shadow]: menuStore.scroll.scrollLeft
              })}
            />
            <th />
            <th />
            <th colSpan={Math.max(0, staffImportStore.titles.length - 7)}></th>
          </tr>
        </thead>
        <tbody>
          <StaffImportTableTitles />
          <StaffImportTableBody />
        </tbody>
      </Table>
      {staffImportStore.isLoadingDuplicateCheck && (
        <StaffImportTableLoader text="Выполняется проверка списка на дубли.  Редактирование недоступно." />
      )}
    </>
  );
};

export default observer(StaffImportTable);
