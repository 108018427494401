import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import styles from "./staffOneSalary.module.scss";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
// import StaffOneSalaryFinanceTable from "./StaffOneSalaryFinanceTable";
import StaffOneSalaryHeader from "./StaffOneSalaryHeader";
import StaffOneSalaryFields from "./StaffOneSalaryFields";
import LoadedComponent from "widgets/LoadedComponent";
import StaffOneSalaryMetrics from "./StaffOneSalaryMetrics";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";
import OperationForm from "features/OperationForm";
import CollapsibleButton from "shared/ui/CollapsibleButton";

const StaffOneSalary = () => {
  const { menuStore, staffOneSalaryStore } = useStores();

  const { id } = useParams();

  const componentRef = useRef();

  // массив рефов для каждого блока, нужны для скролла к ним по нажатию на метрику
  const refsForBlock = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    staffOneSalaryStore.getData(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffSalary");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/salary`
    });
  }, [id]);

  const toggleFieldsShowing = (value: boolean) => () =>
    getKeys(staffOneSalaryStore.conditionBlockList[id]).map((widget) => {
      staffOneSalaryStore.setConditionBlockList(id, widget, value);
    });

  const openedWidgets = getValues(
    staffOneSalaryStore.conditionBlockList[id]
  ).some((cond) => cond);

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoading[id]}
      error={staffOneSalaryStore.error[id]}
    >
      <div ref={componentRef} className={styles.main}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOneSalaryHeader componentRef={componentRef} />
        </ErrorBoundary>

        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOneSalaryMetrics refsForBlock={refsForBlock} />
        </ErrorBoundary>
        {/* <StaffOneSalaryFinanceTable /> */}
        <CollapsibleButton
          onClick={toggleFieldsShowing(openedWidgets ? false : true)}
          openedAllWidgets={openedWidgets}
          id={id}
        />
        <div className={styles.fieldContainer}>
          {staffOneSalaryStore.tableList.map((field) => {
            return (
              <div key={field}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <StaffOneSalaryFields
                    field={field}
                    refsForBlock={refsForBlock}
                  />
                </ErrorBoundary>
              </div>
            );
          })}
        </div>

        <OperationForm
          title="Добавление операции"
          isOpenModal={staffOneSalaryStore.isOpenedAddOperationModal}
          setIsOpenModal={() =>
            staffOneSalaryStore.setIsOpenedAddOperationModal(false)
          }
          clearErrorsMessage={() => staffOneSalaryStore.clearErrorsMessage(id)}
          saveBtnOnClickFunc={staffOneSalaryStore.addOperation}
          fields={staffOneSalaryStore.showFields}
          requiredFields={staffOneSalaryStore.requiredFields}
          nameOneOfStaff={staffOneSalaryStore.nameOneOfStaff[id]}
          type="add"
          errorsMessage={staffOneSalaryStore.errorsMessage[id]}
          successMessage={staffOneSalaryStore.successMessage[id]}
          saveSuccessModal={staffOneSalaryStore.success[id]}
          onSuccessModalClose={() => {
            staffOneSalaryStore.setSuccess(id, false);
          }}
          isLoadingForModal={staffOneSalaryStore.isLoadingForForm[id]}
        />
      </div>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalary);
