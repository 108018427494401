import { observer } from "mobx-react-lite";
import styles from "./windowBody.module.scss";
import { fileUrl } from "stores/utils/consts";
import { useStores } from "stores";
import { Award } from "stores/StaffModule/types/Award";
import { useState } from "react";
import awardDefault from "shared/assets/images/mainIcons/awardDefault.png";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { getKeys } from "shared/utils/helpers/getKeys";
import { ButtonNext } from "shared/ui/ButtonNext";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";
import Alert from "shared/ui/Alert";
import Select from "shared/ui/Inputs/Select";
import { Input } from "shared/ui/Inputs/Input";
import { useFormikContext } from "formik";

type WindowBodyProps = {
  currentAward: Award;
};

const WindowBody = ({ currentAward }: WindowBodyProps) => {
  const { staffOneAwardsStore, menuStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isNewAward, setIsNewAward] = useState(false);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const changeOpenedWindows = (name: string) => {
    openedListName !== name ? setOpenedListName(name) : setOpenedListName(null);
  };

  const showPrevAward = () => {
    const index = getKeys(staffOneAwardsStore.awards).indexOf(
      staffOneAwardsStore.openedAward
    );
    if (index !== 0 && !staffOneAwardsStore.withoutAwards) {
      staffOneAwardsStore.setOpenedAward(
        getKeys(staffOneAwardsStore.awards)[index - 1]
      );
      staffOneAwardsStore.setIsActiveButton(
        staffOneAwardsStore.awardGroups[
          staffOneAwardsStore.awards[staffOneAwardsStore.openedAward].custom
            ?.group
        ]
      );
    }
  };

  const showNextAward = () => {
    const index = getKeys(staffOneAwardsStore.awards).indexOf(
      staffOneAwardsStore.openedAward
    );
    if (
      index !== getKeys(staffOneAwardsStore.awards).length - 1 &&
      !staffOneAwardsStore.withoutAwards
    ) {
      staffOneAwardsStore.setOpenedAward(
        getKeys(staffOneAwardsStore.awards)[index + 1]
      );
      staffOneAwardsStore.setIsActiveButton(
        staffOneAwardsStore.awardGroups[
          staffOneAwardsStore.awards[staffOneAwardsStore.openedAward].custom
            ?.group
        ]
      );
    }
  };

  return (
    <>
      <div className={styles.cols}>
        <div
          className={classNames(styles.leftCol, {
            [styles.rightCol]: staffOneAwardsStore.withoutAwards
          })}
        >
          {staffOneAwardsStore.openedWindowType !== "add" &&
          staffOneAwardsStore.withoutAwards ? (
            <Alert
              errors={{
                head: `У сотрудника нет наград за ${staffOneAwardsStore.isActiveButton.title}`,
                color: "empty"
              }}
            />
          ) : (
            <>
              {staffOneAwardsStore.openedWindowType === "add" ? (
                <>
                  <div className={styles.addAwardData}>
                    <Select
                      options={staffOneAwardsStore.allAwards}
                      name="new_award"
                      label={staffOneAwardsStore.allAwards["id"]?.title}
                      title="Награда"
                      valueName="id"
                      onClick={(option) => {
                        if (option) {
                          staffOneAwardsStore.setOpenedAward(
                            staffOneAwardsStore.allAwards[option["id"]].id
                          );
                          setIsNewAward(true);
                          setFieldTouched("new_award", true);
                          setFieldValue(
                            "building",
                            staffOneAwardsStore.awardGroups[
                              staffOneAwardsStore.allAwards[option["id"]]
                                .group as string
                            ].title
                          );
                          setFieldValue(
                            "author",
                            menuStore.topMenuList.user.content?.fio
                          );
                          setFieldValue(
                            "platform",
                            staffOneAwardsStore.cols.platform.title
                          );
                        } else {
                          staffOneAwardsStore.setOpenedAward("");
                          setIsNewAward(false);
                          setFieldTouched("new_award", false);
                          setFieldValue("building", "");
                          setFieldValue("author", "");
                          setFieldValue("platform", "");
                        }
                      }}
                      required
                    />
                  </div>
                  <div className={styles.addAwardData}>
                    <Input name="building" disabled label="Группа" />
                  </div>
                  {isNewAward &&
                    staffOneAwardsStore.allAwards[
                      staffOneAwardsStore.openedAward
                    ]?.autoset === "platform" && (
                      <div className={styles.addAwardData}>
                        <Input name="platform" disabled label="Площадка" />
                      </div>
                    )}
                  <div className={styles.addAwardData}>
                    <DatePickerField
                      name="award_start"
                      title="Дата выдачи"
                      isCalendarOpened={openedListName === "award_start"}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows("award_start");
                      }}
                      placeholderVisible
                      onChange={() => staffOneAwardsStore.clearErrorsMessage()}
                      required
                    />
                  </div>
                  <div className={styles.addAwardData}>
                    <Input
                      name="author"
                      disabled
                      label={staffOneAwardsStore.cols.author.title}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.aboutAward}>
                  {currentAward?.custom?.autoset === "platform" && (
                    <div className={styles.awardData}>
                      <p className={styles.title}>
                        {staffOneAwardsStore.cols.platform.title}
                      </p>
                      {currentAward?.platform_title}
                    </div>
                  )}

                  <div className={styles.awardData}>
                    <p className={styles.title}>
                      {staffOneAwardsStore.cols.type.title}
                    </p>
                    {currentAward?.type_title}
                  </div>
                  <div className={styles.awardData}>
                    <p className={styles.title}>
                      {staffOneAwardsStore.cols.author.title}
                    </p>
                    {currentAward?.author}
                  </div>
                  {staffOneAwardsStore.openedWindowType === "edit" ? (
                    <DatePickerField
                      name="award_start"
                      title="Дата выдачи"
                      isCalendarOpened={openedListName === "award_start"}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows("award_start");
                      }}
                      placeholderVisible
                      onChange={() => staffOneAwardsStore.clearErrorsMessage()}
                    />
                  ) : (
                    <div className={styles.awardData}>
                      <p className={styles.title}>Дата выдачи</p>
                      {getFormattedDate(currentAward?.award_start)}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.rightCol}>
          <ButtonNext
            disabled={
              getKeys(staffOneAwardsStore.awards).filter((id, index) => {
                return id === staffOneAwardsStore.openedAward && index !== 0;
              })?.length === 0 || staffOneAwardsStore.withoutAwards
            }
            leftDirection
            className={classNames("", {
              [styles.invisible]:
                staffOneAwardsStore.openedWindowType === "edit" ||
                staffOneAwardsStore.openedWindowType === "add"
            })}
            onClick={showPrevAward}
          />
          <div className={styles.imageContainer}>
            {staffOneAwardsStore.withoutAwards ||
            (staffOneAwardsStore.openedWindowType === "add" && !isNewAward) ||
            (isNewAward &&
              staffOneAwardsStore.allAwards[staffOneAwardsStore.openedAward]
                ?.img === "") ? (
              <img src={awardDefault} className={styles.image} />
            ) : isNewAward ? (
              <img
                src={
                  fileUrl +
                  staffOneAwardsStore.allAwards[staffOneAwardsStore.openedAward]
                    ?.img
                }
                className={styles.image}
              />
            ) : (
              <img
                src={(fileUrl + currentAward?.img_src) as string}
                className={styles.image}
              />
            )}
            <div
              className={classNames(styles.awardTitle, {
                [styles.awardAddTitle]:
                  staffOneAwardsStore.openedWindowType === "add" && !isNewAward
              })}
            >
              {staffOneAwardsStore.openedWindowType === "add" && !isNewAward
                ? "Заполните поля"
                : !staffOneAwardsStore.withoutAwards &&
                  staffOneAwardsStore.openedWindowType !== "add"
                ? currentAward?.type_title
                : staffOneAwardsStore.allAwards[staffOneAwardsStore.openedAward]
                    ?.title}
            </div>
          </div>
          <ButtonNext
            disabled={
              getKeys(staffOneAwardsStore.awards).filter((id, index) => {
                return (
                  id === staffOneAwardsStore.openedAward &&
                  index === getKeys(staffOneAwardsStore.awards).length - 1
                );
              })?.length > 0 || staffOneAwardsStore.withoutAwards
            }
            className={classNames("", {
              [styles.invisible]:
                staffOneAwardsStore.openedWindowType === "edit" ||
                staffOneAwardsStore.openedWindowType === "add"
            })}
            onClick={showNextAward}
          />
        </div>
      </div>
      {!isEmpty(staffOneAwardsStore.errorsMessage) ? (
        <Alert errors={staffOneAwardsStore.errorsMessage} />
      ) : null}
    </>
  );
};
export default observer(WindowBody);
