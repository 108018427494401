import styles from "./staffOneSalaryHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { fileUrl } from "stores/utils/consts";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/Avatar.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { RefObject, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import StatusIcon from "shared/ui/StatusIcon";
import { format } from "date-fns";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneSalaryHeaderProps = {
  componentRef: RefObject<null>;
};

const StaffOneSalaryHeader = ({ componentRef }: StaffOneSalaryHeaderProps) => {
  const { menuStore, staffOneStore, staffOneSalaryStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    staffOneSalaryStore.setNameOneOfStaff(
      id,
      `${staffOneStore.info?.surname} ${staffOneStore.info?.name}
    ${staffOneStore.info?.patronymic}`
    );
  }, [staffOneStore.info?.surname]);

  useEffect(() => {
    if (menuStore.scroll.scrollTop >= 30 && !isScroll) {
      setIsScroll(true);
    } else if (menuStore.scroll.scrollTop < 28 && isScroll) {
      setIsScroll(false);
    }
  }, [menuStore.scroll.scrollTop]);

  useEffect(() => {
    staffOneSalaryStore.setIsOpenModal(false);
  }, [id]);

  const openWindowForm = () => {
    staffOneSalaryStore.getBuildingForStaff(
      id,
      format(new Date(), "yyyy-MM-dd")
    );
    staffOneSalaryStore.getOperationTypesForUid(id);
    staffOneSalaryStore.setIsOpenedAddOperationModal(
      !staffOneSalaryStore.isOpenedAddOperationModal
    );
  };

  return (
    <div
      className={classNames(styles.headerBlock, {
        [styles.headerSticky]: isScroll,
        [styles.headerRelative]: !isScroll
      })}
    >
      <div className={styles.staffInfo}>
        <img
          src={
            staffOneStore.info["photo"]?.["src"]
              ? `${fileUrl}${staffOneStore.info?.photo?.src}?${Math.floor(
                  Math.random() * 100000
                )}`
              : Avatar
          }
        />
        <div className={styles.fio}>
          <p>
            {staffOneStore.info?.surname} {staffOneStore.info?.name}{" "}
            {staffOneStore.info?.patronymic}
          </p>
          <div className={styles.TNPrintBlock}>
            <div className={styles.TNBlock}>
              <p className={styles.TNBlockTitle}>ТН</p>
              <p className={styles.TNBlockValue}>{staffOneStore.info?.uid}</p>
            </div>
            <ReactToPrint
              trigger={() => (
                <div className={styles.printBtn}>
                  <StatusIcon icon="iconprinter" color="bw-gray5" />
                  <p>Распечатать</p>
                </div>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
      <div className={styles.addOperationContainer}>
        <Button
          className={styles.tab}
          onClick={openWindowForm}
          id="StaffOneSalaryHeader_openWindow"
          theme={ButtonTheme.SECONDARY}
        >
          <IconExpand
            className={classNames(styles.icon, {
              [styles.transformed]:
                staffOneSalaryStore.isOpenedAddOperationModal
            })}
          />
          Добавить операцию
        </Button>
      </div>
    </div>
  );
};

export default observer(StaffOneSalaryHeader);
